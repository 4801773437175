import apis from "../utils/apis";
import axios from "axios";
import { DataDecryption } from "./GlobalFunctions";

export const getQuestionForm = (
  setQuestions,
  setQuestionLoading,
  dispatch,
  setQuestionForm
) => {
  apis
    .GET({
      endpoint: "/get-relevanceQuestionForm",
      options: {
        type: "json",
      },
    })
    .then((object) => {
      if (object.status === 201) {
        const { questionList } = object.data;
        // console.log("question form", questionList);
        dispatch(setQuestionForm(questionList));
        setQuestions(questionList);
        // console.log(questionList, "Question");
        setQuestionLoading(false);
        // return questionList;
      }
    })
    .catch((error) => {
      console.log(error, "Error");
      setQuestionLoading(false);
    });
};
