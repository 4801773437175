import React from "react";
import {
  Box,
  Paper,
  Typography,
  Avatar,
  CircularProgress,
} from "@mui/material";

// Function to determine background and border color based on easeOfLearning
const getChipColors = (easeOfLearning) => {
  if (easeOfLearning >= 70) {
    return { backgroundColor: "#A8E6A3", borderColor: "#388E3C" }; // Light Green background, Dark Green border
  } else if (easeOfLearning >= 50 && easeOfLearning < 70) {
    return { backgroundColor: "#FFD580", borderColor: "#FFB300" }; // Light Orange background, Dark Orange border
  } else {
    return { backgroundColor: "#FFA6A6", borderColor: "#D32F2F" }; // Light Red background, Dark Red border
  }
};

function SubSkills({
  subSkills,
  parentName,
  bgColor,
  avatarIcon,
  chatGptButton,
}) {
  return (
    <Box>
      {chatGptButton ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            my: 5,
          }}  
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            borderRadius: "15px",
            padding: 0.5,
            // backgroundColor: bgColor || "#fff",
          }}
        >
          {/* <Box sx={{ display: "flex", mb: 2, alignItems: "center" }}>
  {avatarIcon && ( // Only render Avatar if iconUrl is present
    <Avatar
      src={avatarIcon}
      alt="Icons"
      sx={{
        width: "35px",
        height: "35px",
        p: "5px",
        backgroundColor: "#fff",
      }}
      slotProps={{
        img: {
          loading: "lazy",
        },
      }}
    />
  )}

  <Typography
    variant="h6"
    sx={{
      fontWeight: 500,
      pl: 1,
      color: bgColor === "#fff" ? "#000" : "#fff",
    }}
  >
    {parentName}
  </Typography>
</Box> */}
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {subSkills
              ?.slice?.(0, 10)
              .sort((a, b) => b.easeOfLearning - a.easeOfLearning)
              .map((sub, index) => {
                const { backgroundColor, borderColor } = getChipColors(
                  sub.easeOfLearning
                );
                return (
                  <Paper
                    key={index}
                    sx={{
                      p: 1,
                      borderRadius: "10px",
                      backgroundColor, // Dynamic background color
                      border: `1.5px solid ${borderColor}`, // Dynamic border color
                      minWidth: "120px",
                      textAlign: "center",
                    }}
                  >
                    <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
                      {sub.subSkill} {/* Display each subskill name */}
                    </Typography>
                  </Paper>
                );
              })}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default SubSkills;
