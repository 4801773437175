import { Autocomplete, Checkbox, Grid, TextField, Box, FormHelperText } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Typography from "@mui/material/Typography";
// import Tooltip from "@mui/material/Tooltip";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import "../../../styles.css";
import TooltipComponent from "./Tooltip";

function MultiSelectQuestionComponent({ question }) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  // question.options = ["ajay", "aj", "dev"];
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();

  return (
    <Grid item xs={12} sx={{ mb: 2 }}>
      {/* <Box display="flex" alignItems="center" flexDirection={"row"} justifyContent={"center"}> */}
      <Typography
        // variant="h6"
        // component="h1"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className="stepper-question"
        // sx={{ fontWeight: "bold", py: 1 }}
      >
        <Box>
          {question.label}
          {question.rules.required && (
            <>
              &nbsp;<span style={{ color: "red" }}>*</span>
            </>
          )}
        </Box>
        {question.question_info != null &&
        question.question_info.replace(/\s/g, "")?.length != 0 ? (
          <>
            <TooltipComponent message={question?.question_info} />
          </>
        ) : (
          <></>
        )}
      </Typography>
      {/* </Box> */}

      <Controller
        name={question.questionName}
        control={control}
        rules={question.rules}
        render={({ field }) => (
          <Autocomplete
            multiple
            {...field}
            onChange={(event, newValue) => {
              // console.log("newValue", newValue);
              field.onChange(newValue);
            }}
            // size="small"
            id="size-small-standard"
            options={question.options}
            disableCloseOnSelect
            getOptionLabel={(option) => option || ""}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                autoFocus={question?.autoFocus}
                {...params}
                // size="small"
                label={question.placeholder}
              />
            )}
          />
        )}
      />

      {errors[question.questionName]?.type === "required" && (
        // <p className={"errorMsg"}>{question.errorMsg}</p>
        <FormHelperText error className={"errorMsg"}>
          {question.errorMsg}
        </FormHelperText>
      )}
    </Grid>
  );
}
export default MultiSelectQuestionComponent;
