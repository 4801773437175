import React from "react";
import { Typography, Box, Grid, Divider } from "@mui/material";

const DynamicContentRenderer = ({ content }) => {
  const parseContent = (text) => {
    const lines = text.split("\n").filter((line) => line.trim() !== "");

    return lines.map((line, index) => {
      const cleanedLine = line.replace(/\*\*/g, ""); // Remove ** from the text

      if (line.startsWith("###")) {
        return (
          <Typography key={index} variant="h6" gutterBottom>
            {cleanedLine.replace("###", "").trim()}
          </Typography>
        );
      } else if (line.startsWith("-")) {
        return (
          <Typography
            key={index}
            variant="body1"
            style={{ paddingLeft: "1rem", fontWeight: "bold" }}
          >
            {cleanedLine.replace("-", "").trim()}
          </Typography>
        );
      } else if (/^\d+\./.test(line)) {
        return (
          <Typography key={index} variant="body1" gutterBottom>
            {cleanedLine}
          </Typography>
        );
      } else {
        return (
          <Typography key={index} variant="body2" paragraph>
            {cleanedLine}
          </Typography>
        );
      }
    });
  };

  const parsedContent = parseContent(content);

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            {parsedContent.map((line, index) => (
              <Box key={index}>
                {line}
                {index !== parsedContent.length - 1 && (
                  <Divider sx={{ marginY: 1 }} />
                )}
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DynamicContentRenderer;
