import React from "react";
import Lottie from "react-lottie";
import { Grid, Box, Typography, Fade } from "@mui/material";
import animationData from "../animations/error-404.json"; // Replace with your error Lottie file path

const ErrorComponent = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          my: 5,
          textAlign: "center",
        }}
      >
        <Lottie options={defaultOptions} height={200} width={200} />
        <Fade in timeout={800}>
          <Typography
            variant="h6"
            sx={{
              mt: 2,
              fontWeight: 500,
              //   color: "#d32f2f", // Red for error message
            }}
          >
            Please contact the administrator if this issue persists.
          </Typography>
        </Fade>
      </Box>
    </Grid>
  );
};

export default ErrorComponent;
