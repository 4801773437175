import React from "react";
import "./ChatGPTStyle.css";

const InterviewQuestions = ({ response }) => {
  const responseLines = response
    .split("\n")
    .filter((line) => line.trim() !== "")
    .map((line) => line.replace(/\*\*/g, "").replace(/###/g, ""));

  return (
    <div className="chatgpt-container">
      {responseLines.map((line, index) => (
        <div key={index} className="chatgpt-message">
          {line}
        </div>
      ))}
    </div>
  );
};

export default InterviewQuestions;
