import { SET_QUESTION_FORM } from "../action/questionForm";

export default function QuestionFormReducer(state = {}, action) {
  switch (action.type) {
    case SET_QUESTION_FORM:
      const data = action.payload;
      state.questionForm = data;
      return { ...state };
    default:
      return state;
  }
}
