import React, { useEffect, useState, useRef } from "react";
import { ListItem, ListItemIcon, ListItemText, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Box,
  Typography,
  ThemeProvider,
  TextField,
  FormLabel,
  Stack,
  LinearProgress,
  CircularProgress,
  Divider,
  IconButton,
  List,
  Alert,
} from "@mui/material";
// import { useMediaQuery } from "@mui/material";
import { useMediaQuery } from "@mui/material";
// import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, createFilterOptions, Autocomplete } from "@mui/material";
// import { createTheme } from "@mui/material/styles";
import { KeyboardArrowRight, RocketLaunch, Whatshot } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useForm, FormProvider, Controller, useFormContext } from "react-hook-form";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import toast from "react-hot-toast";
import PopularityChart from "./PopularityChart";
import IndustrialUsageChart from "./IndustrialUsageChart";
import PotentialSalaryIncraeseChart from "./PotentialSalaryIncraeseChart";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import DeviceInfoAndLocation from "./global/DeviceInfoAndLocation";

function getFirstWord(inputString) {
  const words = inputString.split(" ");
  if (words.length > 0) {
    return words[0];
  } else {
    return ""; // Return an empty string if there are no words in the input
  }
}

function ScrollDown() {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: "smooth",
  });
}

function TechRelevanceV2() {
  const theme = useTheme();

  const relevenceTheme = createTheme({
    typography: {
      // fontFamily: [" Montserrat", "sans-serif"].join(","),
      fontFamily: `"Montserrat", 'normal'`,
    },
    palette: {
      primary: {
        main: "#000088",
      },
      // secondary: {
      //   main: green[500],
      // },
    },
  });
  const [openTooltip, setOpenToolTip] = React.useState({});
  const methods = useForm({ criteriaMode: "all" });
  const [UserName, setUserName] = useState("");
  const [loadingButton, setLoadingButton] = React.useState(false);
  const [relevanceResult, setRelevanceResult] = useState("");
  const [formdata, setFormdata] = useState("");
  const [chatGptButton, setChatGptButton] = useState(false);
  const [checkSkill, setCheckSkill] = useState("");
  const [skillList, setSkillList] = useState();
  const [interviewQuestions, setInterviewQuestions] = useState();
  let initialCount = 1;
  const [chatGPTData, setChatGPTData] = useState("");
  const [chatgptError, setChatgptError] = useState(false);
  const staticRandomEncodeDecodeString = useFeatureFlag("staticRandomEncodeDecodeString");
  const secretEncodeDecodeString = useFeatureFlag("secretEncodeDecodeString");
  const poweredByChatGPT = useFeatureFlag("Chat_GPT_Boost");
  const pageTitle = "Professionals Tech Innovation Software India | TrueSelfy Tech Relevance";
  const pageDescription =
    "Unlock the Future with TrueSelfy's Tech Innovation in India! Explore Top Tech Software for Professionals. Dive into Tech Relevance Today!";
  const CalculateButtonValue = useFeatureFlag("Calculate_Button_text");
  const textFieldRef = useRef(null);
  const [getInterviewQuestions, setGetInterviewQuestions] = useState(false);
  const apiTimeout = { timeout: 60000 };
  const [value, setValue] = React.useState("figma");
  const [location, setLocation] = useState();
  const [deviceInfo, setDeviceInfo] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const staticQuestions = {
    java: [
      {
        question: "Explain the difference between abstract classes and interfaces in Java.",
        answer:
          "Abstract classes and interfaces are both used to achieve abstraction in Java. An abstract class can have both abstract (unimplemented) and concrete (implemented) methods, while an interface can only have abstract methods. A class can extend only one abstract class, but it can implement multiple interfaces. Abstract classes can have constructors, whereas interfaces cannot. Use abstract classes when you want to share code among closely related classes, and use interfaces when you want to enforce a contract for unrelated classes.",
      },
      {
        question: "What is the significance of the 'static' keyword in Java? Provide examples.",
        answer:
          "In Java, the 'static' keyword is used to declare members (fields or methods) that belong to the class rather than instances of the class. Static members are shared among all instances of the class. For example, a static method can be called using the class name (e.g., ClassName.method()), and a static variable is shared among all instances. Static methods are commonly used for utility methods or factory methods that don't depend on the state of an instance.",
      },
      {
        question:
          "Explain the concept of exception handling in Java. How does it help in writing robust code?",
        answer:
          "Exception handling in Java is a mechanism to handle runtime errors, ensuring the smooth execution of a program. It involves the use of 'try,' 'catch,' 'finally,' and 'throw' keywords. Code that might throw an exception is placed within a 'try' block, and if an exception occurs, it is caught and handled in the 'catch' block. The 'finally' block is used to execute code that must be run, whether an exception is thrown or not. Proper exception handling improves code robustness by preventing unexpected failures and providing a mechanism to gracefully handle errors.",
      },
    ],
    reactjs: [
      {
        question:
          "Explain the component lifecycle methods in React.js. Provide examples of when each method is called.",
        answer:
          "React components go through a lifecycle of events, and each event triggers a set of methods. Some key lifecycle methods include 'componentDidMount,' 'componentDidUpdate,' and 'componentWillUnmount.' 'componentDidMount' is called after the component is rendered for the first time. 'componentDidUpdate' is called after a component's state or props are updated. 'componentWillUnmount' is called before a component is removed from the DOM. These methods allow developers to perform actions at different stages of a component's existence, such as fetching data after the component mounts or cleaning up resources before the component is unmounted.",
      },
      {
        question:
          "What is the virtual DOM in React.js? How does it contribute to the performance of React applications?",
        answer:
          "The virtual DOM is a lightweight copy of the actual DOM in a React application. When the state of a React component changes, a new virtual DOM is created, and the difference (diffing) between the new and old virtual DOMs is calculated. Only the necessary changes are then applied to the actual DOM, resulting in more efficient updates. This minimizes the direct manipulation of the DOM, reducing the performance cost. The virtual DOM allows React to update the UI efficiently, providing a smoother and faster user experience.",
      },
      {
        question: "Explain the purpose of React Hooks. Provide examples of commonly used hooks.",
        answer:
          "React Hooks are functions that enable functional components to manage state and side effects. They were introduced in React 16.8 to allow the use of state and other React features without writing a class. Some commonly used hooks include 'useState' for managing state in functional components, 'useEffect' for handling side effects in a component (e.g., data fetching), and 'useContext' for accessing context in a functional component. Hooks provide a cleaner and more concise way to manage component logic, making functional components as powerful as class components.",
      },
    ],
    nodejs: [
      {
        question:
          "Explain the event loop in Node.js and how it differs from traditional multi-threading.",
        answer:
          "Node.js uses a single-threaded event loop to handle asynchronous operations. The event loop continuously checks the message queue for new events and processes them one at a time. This is different from traditional multi-threading, where each thread runs independently. In Node.js, the non-blocking I/O operations allow the single thread to handle many connections simultaneously, making it efficient for handling a large number of concurrent requests.",
      },
      {
        question:
          "Describe the concept of middleware in Express.js (Node.js). Provide an example of using middleware in an Express application.",
        answer:
          "Middleware in Express.js are functions that have access to the request, response, and the next middleware function in the application's request-response cycle. They can modify the request or response objects, end the request-response cycle, or call the next middleware in the stack. An example of using middleware is adding authentication to routes. For instance, you can create a middleware function to check if a user is authenticated and apply it to specific routes using app.use(authMiddleware).",
      },
      {
        question: "What is npm? How does it contribute to Node.js development?",
        answer:
          "npm (Node Package Manager) is the default package manager for Node.js, allowing developers to install, manage, and share packages or libraries. It simplifies dependency management in Node.js projects by providing a centralized repository of reusable code modules. Developers can use npm to install third-party packages, manage project dependencies, and publish their own packages. npm also facilitates version management, making it easy to specify and install specific versions of packages. It plays a crucial role in building scalable and maintainable Node.js applications by promoting code reuse and collaboration.",
      },
    ],
  };

  function checkSkillType(year) {
    const currentYear = new Date().getFullYear();
    const minYearDiff = 5;
    const minYearForCurrentSkill = 1;
    const minYearForOutdated = 4;
    if (year > currentYear) {
      return `Skill trending and in demand until ${year}`;
    } else if (year >= currentYear - minYearForCurrentSkill) {
      return `Skill current and in demand until ${year}`;
    } else if (year >= currentYear - minYearForOutdated) {
      return `Skill getting outdated fast ${year}`;
    } else if (year <= currentYear - minYearDiff) {
      return `Skill obselete ${year}`;
    }
  }
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_BASE_URL + `/get-relevanceSkill?skills=java`, {})
      .then((object) => {});
  }, []);

  useEffect((item) => {
    DeviceInfoAndLocation(setLocation, setDeviceInfo);
  }, []);
  // const openai = new OpenAI({
  //   apiKey: "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", // defaults to process.env["OPENAI_API_KEY"]
  //   dangerouslyAllowBrowser: true,
  // });

  const messages = [
    "Hello, how can I assist you?",
    "I can provide information and answer questions.",
    "Just type your query, and I will do my best to help!",
  ];

  function checkYear(year) {
    const currentYear = new Date().getFullYear();
    const minYearDiff = 5;
    const minYearForOutdatedSkill = 4;
    const minYearForCurrentSkill = 1;

    if (year > currentYear) {
      const labelSuggestion = (
        <Typography
          sx={{
            fontFamily: "Montserrat",
            // fontSize: "20px",
            color: "#232323",

            textAlign: "center",
          }}
          // mb={{ xs: 2, md: 2, lg: 3, xl: 3 }}
          variant="h6"
        >
          <span
            style={{
              // fontSize: "20px",
              fontWeight: 700,
              color: "#26801E",
            }}
          >
            Trending{" "}
          </span>{" "}
          : You are in demand, but keep upgrading
        </Typography>
      );
      return {
        label: `Technical skill trending and in demand until ${year}`,
        barPrimaryColor: "green",
        barSecondaryColor: "#A5D6A7",
        labelSuggestion,
      };
    } else if (year >= currentYear - minYearForCurrentSkill) {
      const labelSuggestion = (
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: "20px",
            color: "#232323",
            mb: "47px",
            textAlign: "center",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: 700,
              color: "darkorange",
            }}
          >
            • Current
          </span>{" "}
          <spna>: Your tech is current, but it is getting outdated fast</spna>
        </Typography>
      );
      return {
        label: `Technical skill in demand until ${year}`,
        barPrimaryColor: "darkorange",
        barSecondaryColor: "#ffcc80",
        labelSuggestion,
      };
    } else if (year >= currentYear - minYearForOutdatedSkill) {
      const labelSuggestion = (
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: "20px",
            color: "#232323",
            mb: "47px",
            textAlign: "center",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: 700,
              color: "#ffc107",
            }}
          >
            • Getting outdated
          </span>{" "}
          <spna>: Upskill soon to stay ahead in the tech industry</spna>
        </Typography>
      );
      return {
        label: `Technical skill getting outdated fast ${year}`,
        barPrimaryColor: "#ffc107",
        barSecondaryColor: "#fff59d",
        labelSuggestion,
      };
    } else if (year <= currentYear - minYearDiff) {
      const labelSuggestion = (
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: "24px",
            color: "#232323",
            mb: "47px",
            textAlign: "center",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: 700,
              color: "red",
            }}
          >
            • Obsolete
          </span>{" "}
          : Please learn new age skills now
        </Typography>
      );
      return {
        label: `Technical skill obselete ${year}`,
        barPrimaryColor: "red",
        barSecondaryColor: "#FFCDD2",
        labelSuggestion,
      };
    }
  }

  function getTopSkill(skillArray) {
    let maxExpiryYear = -Infinity;
    let maxExpiryYearObject = null;

    // Iterate through the array
    for (const obj of skillArray) {
      if (obj.expiryYear > maxExpiryYear) {
        maxExpiryYear = obj.expiryYear;
        maxExpiryYearObject = obj;
      }
    }
    const topSkillSuggestion = checkYear(maxExpiryYearObject?.expiryYear);

    return {
      maxExpiryYear,
      labelSuggestion: topSkillSuggestion?.labelSuggestion,
      maxExpiryYearObject,
    };
  }

  // Data Encryption function
  function DataEncryption(json) {
    const jsonString = JSON.stringify(json);
    const customStringJson = secretEncodeDecodeString + jsonString + secretEncodeDecodeString;
    const newCustomStringJson = customStringJson.replace(/"/g, staticRandomEncodeDecodeString);
    const encodedString = btoa(newCustomStringJson);
    return encodedString;
  }
  function DataDecryption(jsonString) {
    const decodedString = atob(jsonString);
    const getOldStringOne = decodedString.replace(new RegExp(secretEncodeDecodeString, "g"), "");
    const str = staticRandomEncodeDecodeString;
    const escapedString = str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(escapedString, "g");
    const getOldStringTwo = getOldStringOne.replace(regex, '"');
    const json = JSON.parse(getOldStringTwo);
    return json;
  }

  // tech relevance handler
  const onSubmit = (data) => {
    setLoadingButton(true);
    setUserName(data?.name);
    setFormdata(data);
    setCheckSkill("");
    setGetInterviewQuestions(false);
    data.userLocation = location;
    data.deviceInfo = deviceInfo;

    // console.log("data", data);
    try {
      axios
        .post(process.env.REACT_APP_API_BASE_URL + "/get-relevanceResult", DataEncryption(data), {
          options: {
            type: "json",
          },
        })
        .then((object) => {
          const data = DataDecryption(object.data);
          // console.log("Data", data);
          setRelevanceResult(data);
          setSkillList(data?.topPrimarySkill);
          // console.log(data, "data");
          // console.log(data, "data");
          setLoadingButton(false);
          window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: "smooth",
          });
          // methods.reset("");
          // setValue("skills", "");
        })
        .catch((error) => {
          if (
            error?.response?.status === 500 &&
            error?.response?.data?.message === "Email verification failed"
          ) {
            methods.setError("email", {
              type: "validation",
              message: "Email not valid !!",
            });
            setRelevanceResult("");
          }

          setLoadingButton(false);
        });
    } catch (error) {
      // console.log(error);
      setLoadingButton(false);
    }
  };

  // chatgpt response handler
  const handleSendClick = async (name) => {
    setChatgptError(false);
    setCheckSkill(name);
    setGetInterviewQuestions(false);
    const skill = getTopSkill(relevanceResult?.topPrimarySkill);
    try {
      setChatGptButton(true);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "/get-chatgpt-response",
          DataEncryption({ skillName: name }),
          {
            options: {
              type: "json",
            },
          }
        )
        .then((object) => {
          setChatGptButton(false);
          const data = DataDecryption(object.data);
          let gptdata = JSON.parse(data?.result);
          ScrollDown();

          // console.log(object.data, "Chatgpt data");

          // console.log(gptdata, "gptdata1");
          if (
            gptdata.length === 0 ||
            gptdata === null ||
            gptdata === undefined ||
            Object.keys(gptdata).length === 0
          ) {
            setChatgptError(true);
            setChatGPTData("");
            // console.log(gptdata, "gptdata");
            toast.error("Something went wrong try again");
          } else {
            // setChatGPTData(gptdata);
            setChatGPTData(gptdata);
            // console.log(gptdata, "gptdata");
          }
        })
        .catch((error) => {
          setChatgptError(true);
          setChatGptButton(false);
          toast.error("Something went wrong try again");
        });
    } catch (error) {
      setChatgptError(true);
      setChatGptButton(false);
      toast.error("Something went wrong try again");
    }
  };

  // get interview questions
  const handleGetInterviewQuestions = () => {
    setGetInterviewQuestions(true);
    ScrollDown();
    // console.log({ primarySkill: skillList }, "payload");

    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "/get-interviewQuestion",
        DataEncryption({ primarySkill: skillList }),
        {
          headers: {
            "Content-Type": { json: "application/json", form: "application/x-www-form-urlencoded" },
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
          ...apiTimeout,
        }
      )
      .then((object) => {
        const data = DataDecryption(object.data);
        setInterviewQuestions(DataDecryption(object.data));
        // console.log(DataDecryption(object.data), "interview questions");
      })
      .catch((error) => {
        toast.error("Something went wrong, try again");
      });
  };

  const RetryGPT = (name) => {
    if (initialCount <= 3) {
      initialCount += 1;
      handleSendClick(name);
    } else {
      return;
    }
  };

  // scroll down hook
  useEffect(() => {
    if (chatGptButton === true) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
    if (relevanceResult?.length !== 0) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [chatGptButton, relevanceResult]);

  // Tech Relevance form
  function TechRelevanceForm() {
    const {
      control,
      setValue,
      getValues,
      formState: { errors },
    } = useFormContext();
    const [open, setOpen] = useState(false); // if dropdown open?
    const [autoValue, setAutoValue] = useState(getValues("primarySkill") || []); // if dropdown open?
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [noOptions, setNoOptions] = useState(false);

    const filter = createFilterOptions({
      stringify: (option) => option.name,
    });

    // useEffect(() => {
    //   setError("email", {
    //     type: "validation",
    //     message: "Email not valid !!",
    //   });
    // }, [setEmailError]);

    useEffect(() => {
      setValue("primarySkill", autoValue);
    }, [autoValue]);
    return (
      <React.Fragment>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} md={4}>
            <FormLabel
              sx={{
                fontFamily: "Montserrat, cursive",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "42px",
                color: "#262655",
              }}
            >
              Name
            </FormLabel>
            <Controller
              control={control}
              name={"name"}
              // sx={{
              //   "& .Mui-focused": {
              //     color: "#0391E8 !important",
              //   },
              // }}

              render={({ field }) => (
                <TextField
                  id="name"
                  {...field}
                  fullWidth
                  required
                  label={"Enter your Name"}
                  variant="outlined"
                  inputRef={textFieldRef}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormLabel
              sx={{
                fontFamily: "Montserrat, cursive",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "42px",
                color: "#262655",
              }}
            >
              Email
            </FormLabel>
            <Controller
              control={control}
              name={"email"}
              rules={{
                required: "Email Address is required",
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message:
                    "Oh no, that doesn't look like a valid email! Please double-check and try again.",
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors?.email ? true : false}
                  id="email"
                  label={"Enter your Email"}
                  fullWidth
                  required
                  type="email"
                  helperText={
                    <>
                      {errors?.email && errors?.email.message}
                      {errors?.email && errors.type === "validation" ? errors?.email?.message : ""}
                    </>
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormLabel
              sx={{
                fontFamily: "Montserrat, cursive",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "42px",
                color: "#262655",
              }}
            >
              Skills
            </FormLabel>
            <Controller
              name={"primarySkill"}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  noOptionsText={noOptions ? "No options found" : "Start typing to get options"}
                  {...field}
                  open={open}
                  onOpen={() => {
                    setNoOptions(false);
                    setOpen(true);
                    setData([]);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  loading={loading}
                  multiple
                  autoComplete={true}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  value={autoValue}
                  onChange={(event, newValue) => {
                    if (newValue.slice(-1)[0] && newValue.slice(-1)[0].inputValue != undefined) {
                      setAutoValue([
                        ...autoValue,
                        {
                          name: newValue.slice(-1)[0].inputValue,
                          other: true,
                        },
                      ]);
                    } else {
                      setAutoValue(newValue);
                    }
                    setData([]);
                  }}
                  filterSelectedOptions={true}
                  onInputChange={(event, value) => {
                    setNoOptions(true);
                    // setLoading(true);
                    const trimmedValue = value?.trim();
                    if (trimmedValue) {
                      const encodedSkillName = Array.from(trimmedValue)
                        .map((char) => {
                          const isSpecialSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(char);
                          return isSpecialSymbol ? encodeURIComponent(char) : char;
                        })
                        .join("");
                      const endpoint = `/get-relevanceSkill?skills=${encodedSkillName}`;
                      // console.log("data", data);
                      getSkillOptions(setData, endpoint, setLoading);
                    } else {
                      setLoading(false);
                    }
                  }}
                  id="free-solo-dialog-demo"
                  options={data}
                  getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.name;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  filterOptions={(options, params) => {
                    params.inputValue = params.inputValue.trim();
                    const filtered = filter(options, params);
                    return filtered;
                  }}
                  renderOption={(props, option, { inputValue }) => {
                    const matches = match(option.name, inputValue.trim());
                    const parts = parse(option.name, matches);

                    return (
                      <li {...props} key={option.name}>
                        <div>
                          {parts.map((part, index) => (
                            <span
                              key={index}
                              style={{
                                color: part.highlight ? "red" : "inherit",
                                fontWeight: part.highlight ? 700 : 400,
                              }}
                            >
                              {part.text}
                            </span>
                          ))}
                        </div>
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // autoFocus={question?.autoFocus}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      error={errors?.primarySkill ? true : false}
                      helperText={<>{errors?.primarySkill && "Skills cannot be empty"}</>}
                      label={"Select multiple options"}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  // get top skill details handler
  var cancelToken = null;
  function getSkillOptions(setData, endpoint, setLoading) {
    setData([]);
    setLoading(true);
    if (cancelToken) {
      // console.log("cancelToken", cancelToken);
      cancelToken.cancel("Previous request cancelled");
    }
    // console.log("");
    try {
      cancelToken = axios.CancelToken.source();
      axios
        .get(process.env.REACT_APP_API_BASE_URL + endpoint, {
          cancelToken: cancelToken.token,
        })
        .then((object) => {
          if (object?.status === 201) {
            var data = _.remove(DataDecryption(object.data), function (n) {
              return n.name !== "Other";
            });
            setData(data);
          } else {
            setData([]);
          }
          setLoading(false);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            // Handle other errors here
            console.error("Error:", error);
          }
        });
    } catch (error) {
      // Handle any unexpected errors here
      console.error("Error:", error);
      setLoading(false);
    }
  }

  const handleTooltipClose = (key) => {
    // setOpenToolTip((prevState) => ({ ...prevState, [key]: false }));
    setOpenToolTip({});
  };

  const handleTooltipOpen = (key) => {
    if (openTooltip[key] === true) {
      setOpenToolTip((prevState) => ({ ...prevState, [key]: false }));
    } else {
      setOpenToolTip({});
      setOpenToolTip((prevState) => ({ ...prevState, [key]: true }));
    }
  };

  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
  ))(`
      // border: 1px solid #008;
      // color: black;
      // background-color: #D6EFFF;
      // background-color: #FFF;
      // border-radius: 22px;
      // padding:"11px 34px !important";
      // filter: drop-shadow(0px 0px 12px rgba(0, 0, 136, 0.55));
  `);

  // custom tooltip component
  const CustomTooltipTitle = (data, index) => (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
        }}
      >
        {" "}
        <IconButton
          onClick={() => {
            handleTooltipClose(index);
          }}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            maxWidth: 164,
            minWidth: 120,
            width: "100%",
            flex: 1,
            padding: 0.5,
          }}
        >
          <Typography
            color="primary"
            textAlign="center"
            sx={{
              fontFamily: "Montserrat, cursive",
              fontSize: { xs: "14px", md: "16px" },
              fontWeight: 700,
              mb: "20px",
              textAlign: "center",
              padding: 0.5,
            }}
          >
            Ease of learning
          </Typography>
          <Box pt={{ xs: 3, sm: 0, md: 2, lg: 3, xl: 3 }}>
            <Typography
              color="primary"
              textAlign="center"
              sx={{
                fontFamily: "Montserrat, cursive",
                fontSize: { xs: "24px", md: "24px" },
                fontWeight: 700,
                textAlign: "center",
              }}
              pr={{ xs: 1, md: 0, lg: 0, xl: 0 }}
            >
              {data?.data?.easeOfLearning}
            </Typography>
          </Box>
        </Box>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{
            flex: 0.1,
            borderColor: "#008",
          }}
        />
        <Box sx={{ p: 0.5, flex: 1 }}>
          <Typography
            color="primary"
            textAlign="center"
            sx={{
              fontFamily: "Montserrat, cursive",
              fontSize: { xs: "14px", md: "16px" },
              fontWeight: 700,
              // mb: "15px",
              textAlign: "center",
              padding: 1,
            }}
          >
            Potential Salary Increase
          </Typography>
          <PotentialSalaryIncraeseChart value={data?.data?.potentialSalaryIncreasePercentage} />
        </Box>
      </Box>
    </React.Fragment>
  );

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <ThemeProvider theme={relevenceTheme}>
      <React.Fragment>
        <Grid
          container
          display={"flex"}
          flexDirection={"row"}
          justifyContent={{
            xs: "center",
            sm: "center",
            md: "center",
            lg: "left",
          }}
        >
          {/* Header Section */}
          <Grid item>
            <Box p={3}>
              <a href="/">
                <img
                  src={"../../assets/images/TrueSelfy-logo.svg"}
                  alt="true-selfy"
                  style={{
                    maxWidth: `${isMobile ? "80px" : ""}`,
                  }}
                />
              </a>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems={"flex-start"}
          p={{
            xs: "15px",
            sm: "30px 50px",
            md: "30px 50px",
            lg: "30px 70px 30px 70px",
          }}
          spacing={{ xs: 4, md: 5, lg: 5, xl: 7 }}
          justifyContent={"center"}
        >
          {/* Techrelevance first Section */}
          <Grid item xs={12}>
            <Box
              component="div"
              sx={{
                backgroundImage: {
                  xs: `url('/assets/images/tech-relevance-backimg.png')`,
                  md: `url('/assets/images/tech-relevance-backimg.png')`,
                },
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                p: { xs: "20px", md: "46px 75px" },
                borderRadius: { xs: "20px", md: "66px" },
                // color: "red",
                overflow: "hidden",
              }}
            >
              <Grid>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    fontSize: { xs: "24px", md: "52px" },
                    lineHeight: { xs: "40px", md: "62px" },
                    color: "#FFC218",
                    mb: "48px",
                    textAlign: { xs: "center", md: "center" },
                    // wordBreak: "break-word",
                  }}
                  variant="h1"
                >
                  Check your TechRelevance
                </Typography>
              </Grid>
              <Grid container spacing={2} display={"flex"} alignItems={"center"}>
                <Grid item xs={12} md={6} order={{ xs: 2, sm: 2, md: 1 }}>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: { xs: "24px", md: "38px", lg: "38px", xl: "52px" },
                      fontWeight: 600,
                      // lineHeight: "75px",
                      wordBreak: "break-word",
                      color: "#fff",
                      paddingBottom: isMobile ? "10px" : "0px",
                      textAlign: isMobile ? "center" : "left",
                    }}
                  >
                    Craft Your Career Journey {poweredByChatGPT && "with AI!"}
                  </Typography>
                  <Box py={2} display={"flex"} justifyContent={{ xs: "center", md: "left" }}>
                    <Button
                      variant="contained"
                      sx={{
                        // minWidth: { xs: "150px", md: "150px", lg: "150px", xl: "218px" },
                        borderRadius: "55px",
                        background: `#FFF`,
                        color: "#008",
                        fontSize: isMobile ? "16px" : "28px",
                        backgroundColor: "#FFF !important",
                        textTransform: "capitalize",
                        fontWeight: 600,
                        // p:'0px 29px'
                      }}
                      onClick={() => {
                        window.scrollTo({
                          top: document.documentElement.scrollHeight,
                          behavior: "smooth",
                        });
                        textFieldRef.current.focus();
                      }}
                    >
                      Start Now
                    </Button>
                  </Box>

                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: { xs: "18px", md: "24px" },
                      fontWeight: 400,
                      lineHeight: "30px",
                      wordBreak: "break-word",
                      color: "#fff",
                      // color: { xs: "#000", md: "#fff" },
                      mb: "10px",
                    }}
                  >
                    Technology's significance lies in its problem-solving, life-enhancing, and
                    efficiency-boosting capabilities. It constantly evolves to meet changing needs,
                    but also faces obsolescence as newer, more efficient solutions emerge.
                  </Typography>
                  <br />
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: { xs: "18px", md: "24px" },
                      fontWeight: 400,
                      lineHeight: "30px",
                      wordBreak: "break-word",
                      color: "#fff",
                      // color: { xs: "#000", md: "#fff" },
                      mb: "10px",
                    }}
                  >
                    Innovations can quickly make once-vital technologies outdated, emphasizing the
                    importance of staying ahead. In this rapidly changing landscape, adaptability
                    becomes key.
                  </Typography>
                  <br />
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: { xs: "18px", md: "24px" },
                      fontWeight: 600,
                      lineHeight: "30px",
                      wordBreak: "break-word",
                      color: "#fff",
                      // color: { xs: "#000", md: "#fff" },
                      mb: "10px",
                    }}
                  >
                    Discover how long your skills will remain relevant in this evolving tech world.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: "flex",
                    alignItems: "normal",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                  order={{ xs: 1, sm: 1, md: 2 }}
                >
                  <Box
                    component="div"
                    sx={{
                      maxWidth: 600,
                      width: "100%",
                      height: "auto",
                      m: "0px auto",
                    }}
                  >
                    <img
                      src="/assets/images/tech-relevance-sideimg.svg"
                      alt="tech-relevence-img"
                      width="100%"
                      height="100%"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {/* User Details Section */}
          <Grid item xs={12}>
            <Typography
              // color={"primary"}
              sx={{
                fontFamily: "Montserrat, cursive",
                fontWeight: 700,
                fontSize: { xs: "30px", md: "45px" },
                textAlign: "center",
                // lineHeight: { xs: "40px", md: "62px" },
                // color: "#fff",
                mb: `${isMobile ? "18px" : "37px"}`,
                wordBreak: "break-word",
                color: "rgba(38, 38, 85, 1)",
              }}
            >
              Enter Your Details
            </Typography>
            <React.Fragment>
              <FormProvider {...methods}>
                <form id="tech-relevance" onSubmit={methods.handleSubmit(onSubmit)}>
                  <TechRelevanceForm />
                  <Grid>
                    <Box pt={4}>
                      {loadingButton ? (
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              my: 5,
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        </Grid>
                      ) : (
                        <Button
                          type="submit"
                          form="tech-relevance"
                          // variant="contained"
                          sx={{
                            maxWidth: "333px",
                            width: "100%",
                            display: "block",
                            m: "0px auto",
                            color: "#FFF",
                            P: "12px",
                            fontSize: "24px",
                            fontWeight: 600,
                            borderRadius: "55px",
                            textTransform: "capitalize",
                            background: "#009EF7",
                            border: "1px solid transparent",
                            transition: "all 0.3s linear",
                            "&:hover": {
                              border: "1px solid #008",
                              // background: "rgba(0, 158, 247, 0.10)",
                              background: "#FFF",
                              color: "#008",
                              transition: "all 0.3s linear",
                            },
                          }}
                          loading={loadingButton}
                        >
                          {" "}
                          Check TechRelevance
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </form>
              </FormProvider>
            </React.Fragment>
          </Grid>
          {/* Tech relevance Section */}
          {relevanceResult?.length !== 0 && relevanceResult !== undefined && !loadingButton ? (
            <>
              <Grid item xs={12}>
                <Typography
                  // color={"primary"}
                  sx={{
                    fontFamily: "Montserrat, cursive",
                    fontSize: { xs: "30px", md: "45px" },
                    textAlign: "center",
                    mb: "37px",
                    fontWeight: 700,
                    wordBreak: "break-word",
                    color: "rgba(38, 38, 85, 1)",
                  }}
                >
                  {UserName.length !== 0 ? `${getFirstWord(UserName)}'s TechRelevance` : ""}
                </Typography>
                <Grid container spacing={{ xs: 2, md: 5 }} rowSpacing={1}>
                  <Grid item xs={12} md={6}>
                    <Box
                      component="div"
                      // px={{ xs: 3, md: 10, lg: 10 }}
                      // py={{ xs: 3, md: 10, lg: 10 }}
                      sx={{
                        p: { xs: "25px", md: "30px", lg: "30px", xl: "60px" },
                        border: "1px solid #008",
                        borderRadius: "39px",
                        boxShadow: "0px 0px 8px 0px #D9D9FF",
                        height: "100%",
                        boxSizing: "inherit",
                      }}
                    >
                      <Box
                        sx={{
                          maxWidth: { xs: "200px", xl: "270px" },
                          width: "100%",
                          height: "auto",
                          m: "0px auto",
                          mb: "36px",
                        }}
                      >
                        <img
                          src="/assets/images/check-icon.svg"
                          style={{ width: "100%", height: "100%" }}
                          alt="check-icon"
                        />
                      </Box>
                      <Typography
                        color="primary"
                        sx={{
                          fontFamily: "Montserrat, cursive",
                          fontSize: "32px",
                          fontWeight: 700,
                          textAlign: "center",
                        }}
                      >
                        Technical validity until
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Montserrat, cursive",
                          fontSize: "65px",
                          fontWeight: 700,
                          textAlign: "center",
                          color: "262655",
                        }}
                      >
                        {getTopSkill(relevanceResult?.topPrimarySkill).maxExpiryYear}
                      </Typography>
                      {getTopSkill(relevanceResult?.topPrimarySkill)?.labelSuggestion}

                      <Box
                        component="div"
                        sx={{
                          height: "1.5px",
                          background: "#000000",
                          maxWidth: "193px",
                          width: "100%",
                          m: "0px auto",
                          mt: "15px",
                        }}
                      ></Box>

                      <Typography
                        sx={{
                          textAlign: "center",
                          color: "#26801E",
                          fontFamily: "Montserrat, cursive",
                          fontWeight: 700,
                          fontSize: "24px",
                        }}
                        py={2}
                      >
                        Determine your potential salary by calculating TechWorth
                      </Typography>
                      <Button
                        sx={{
                          maxWidth: "370px",
                          width: isMobile ? "100%" : "100%",
                          display: "block",
                          m: "0px auto",
                          color: "#FFF",
                          // P: "12px",
                          fontSize: isMobile ? "16px" : "24px",
                          fontWeight: 600,
                          borderRadius: "55px",
                          textAlign: "center",
                          textTransform: "capitalize",
                          background: "#26801E",
                          backgroundColor: "#26801E !important",
                        }}
                        component={"a"}
                        href={"https://techworth.trueselfy.com/"}
                      >
                        {CalculateButtonValue}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      component="div"
                      sx={{
                        p: { xs: "25px", md: "35px" },
                        border: "1px solid #008",
                        borderRadius: "39px",
                        boxShadow: "0px 0px 8px 0px #D9D9FF",
                        height: "100%",
                        boxSizing: "inherit",
                      }}
                    >
                      <Grid
                        container
                        // direction="row"
                        alignItems={"center"}
                        sx={{ mb: isMobile ? "5px" : "0px" }}
                        justifyContent={{ xs: "center", lg: "space-between" }}
                      >
                        <Grid item xs={12} lg={8}>
                          <Box
                            sx={{
                              display: "flex",
                              gap: 2,
                              alignItems: "center",
                              justifyContent: { xs: "center", lg: "flex-start" },
                            }}
                          >
                            <Box
                              sx={{
                                mr: "20px",
                                width: { xs: "46px", md: "58px" },
                                height: "100%",
                              }}
                            >
                              <img
                                src="/assets/images/skill-score-relevence.svg"
                                width={"100%"}
                                height="auto"
                                alt="skill-score"
                              />
                            </Box>

                            <Typography
                              sx={{
                                fontFamily: "Montserrat, cursive",
                                fontWeight: 700,
                                fontSize: { xs: "28px", md: "32px" },
                                letterSpacing: "0.5px",
                              }}
                              variant="h5"
                            >
                              Skill score
                            </Typography>
                          </Box>
                        </Grid>
                        {!isMobile && (
                          <Grid item xs={0} lg={4}>
                            <Typography
                              sx={{
                                fontFamily: "Montserrat, cursive",
                                fontSize: { sm: "18px", xl: "24px" },
                                fontWeight: 700,
                                textAlign: { xs: "center", md: "center", lg: "center" },
                                color: "#262655",
                              }}
                            >
                              Use AI Boost Career Counsellor
                            </Typography>
                          </Grid>
                        )}
                      </Grid>

                      {isMobile && (
                        <>
                          <Typography
                            sx={{
                              fontFamily: "Montserrat, cursive",
                              background: `linear-gradient(148deg, #0BA2FF -2.88%, #C2187F 87.25%, #100C3D 135.99%)`,
                              backgroundClip: "text",
                              WebkitBackgroundClip: "text",
                              WebkitTextFillColor: "transparent",
                              fontSize: "18px",
                              fontWeight: 700,
                              textAlign: "center",
                            }}
                            className="typing-demo"
                          >
                            Use AI Boost Career Counsellor
                          </Typography>
                        </>
                      )}

                      {relevanceResult?.topPrimarySkill?.map((item, index) => (
                        <Box
                          sx={{
                            display: "flex",
                            gap: { xs: 2, lg: 2, xl: 15 },
                            alignItems: {
                              xs: "center",
                              sm: "flex-end",
                              md: "center",
                              lg: "flex-end",
                              xl: "flex-end",
                            },
                            justifyContent: "space-between",
                            my: { xs: "20px", md: "30px" },
                          }}
                          flexDirection={{
                            xs: "column",
                            sm: "row",
                            md: "column",
                            lg: "row",
                            xl: "row",
                          }}
                        >
                          <Box flexGrow={1} sx={{ width: "100%" }}>
                            <Typography
                              sx={{
                                fontFamily: "Montserrat, cursive",
                                fontSize: "22px",
                                fontWeight: 700,
                                display: "block",
                                mb: "6px",
                                mr: "20px",
                                wordBreak: "break-all",
                              }}
                            >
                              {item?.name}
                            </Typography>

                            <Box
                              sx={{
                                display: "flex",
                                gap: 2,
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography sx={{ fontSize: "18px", color: "#262655" }}>
                                {item.type === "y"
                                  ? checkYear(item?.expiryYear).label
                                  : checkSkillType(item?.expiryYear)}
                              </Typography>
                              <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>
                                {item?.score <= 0 ? "20" : Math.round(item?.score)}%
                              </Typography>
                            </Box>
                            <Box sx={{ width: "100%" }}>
                              <LinearProgress
                                sx={{
                                  height: "10px",
                                  borderRadius: "20px",
                                  "& .MuiLinearProgress-bar": {
                                    backgroundColor: checkYear(item?.expiryYear).barPrimaryColor,
                                  },
                                  backgroundColor: checkYear(item?.expiryYear).barSecondaryColor,
                                }}
                                color="error"
                                variant="determinate"
                                value={item?.score <= 0 ? 20 : Math.round(item?.score)}
                              />
                            </Box>
                          </Box>
                          {poweredByChatGPT && (
                            <Button
                              sx={{
                                maxWidth: "127px",
                                width: "100%",
                                marginLeft: "15px",
                                color: "#FFF",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                                padding: "7px 12px",
                                borderRadius: "27px",
                                // background: `linear-gradient(148deg, #0BA2FF -2.88%, #C2187F 87.25%, #100C3D 135.99%)`,
                                background: "#0BA2FF",
                                transition: "all 0.3s linear",
                                "&:hover": {
                                  background:
                                    "linear-gradient(148deg, #0BA2FF -2.88%, #100C3D 135.99%)",
                                  transition: "all 0.3s linear",
                                },
                                "&:active,&:focus": {
                                  background: "#FFF",
                                  padding: "4px 10px",
                                  border: "2px solid #2F2876",
                                  color: "#2F2876 !important",
                                },
                              }}
                              endIcon={
                                <RocketLaunch
                                  sx={{
                                    fontSize: "16px",
                                  }}
                                />
                              }
                              onClick={() => handleSendClick(item?.name)}
                            >
                              AI Boost
                            </Button>
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box textAlign={"left"} pt={0.5}>
                      <Alert severity="info" sx={{ background: "#fff" }}>
                        Recommendations and potential salary increase percentages provided are for
                        informational purposes only and should be used as suggestions, not
                        guarantees
                      </Alert>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {/* AI Boost powered Section */}
              <Grid item xs={12}>
                <Grid container spacing={5} sx={{ alignItems: "flex-end" }}>
                  <Grid item xs={12} xl={12}>
                    {checkSkill !== "" && poweredByChatGPT && !chatgptError ? (
                      <>
                        <Typography
                          color={"primary"}
                          sx={{
                            fontFamily: "Montserrat, cursive",
                            fontSize: { xs: "30px", md: "45px" },
                            fontWeight: 700,
                            textAlign: "center",
                            mb: "52px",
                            wordBreak: "break-word",
                          }}
                        >
                          AI Boost powered by ChatGPT
                        </Typography>
                        <Box
                          component="div"
                          sx={{
                            backgroundImage: `url('/assets/images/tech-relevence-chatgpt-bg-01.svg')`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            p: { xs: "20px", md: "46px 44px" },
                            borderRadius: { xs: "20px", md: "66px" },
                            overflow: "hidden",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center", mb: "43px" }}>
                            <Box
                              sx={{
                                mr: "20px",
                                width: { xs: "46px", md: "58px" },
                                height: "100%",
                              }}
                            >
                              <img
                                src="/assets/images/bulb-img.png"
                                width="100%"
                                height="100%"
                                alt={"bulb-img"}
                              />
                            </Box>
                            <Typography
                              sx={{
                                fontFamily: "Montserrat, cursive",
                                fontSize: { xs: "24px", md: "32px" },
                                fontWeight: 700,
                                color: "#FFF",
                              }}
                            >
                              {" "}
                              {checkSkill}
                            </Typography>
                          </Box>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} lg={3}>
                              <Box
                                sx={{
                                  // maxWidth: "377px",
                                  // width: "100%",
                                  border: "1px solid #000088",
                                  // p: "30px",
                                  borderRadius: "39px",
                                  background: "#F3FBFF",
                                  height: "100%",
                                  boxSizing: "inherit",
                                }}
                                p={{ xs: 2, md: 3, lg: 2 }}
                              >
                                <Typography
                                  color="primary"
                                  sx={{
                                    fontFamily: "Montserrat, cursive",
                                    textAlign: "center",
                                    fontSize: { xs: "24px", lg: "28px" },
                                    fontWeight: 700,
                                    // mb: `${isMobile ? "0px" : "48px"}`,
                                  }}
                                >
                                  Popularity
                                </Typography>
                                {chatGptButton ? (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      my: 5,
                                    }}
                                  >
                                    <CircularProgress />
                                  </Box>
                                ) : (
                                  <Box pt={{ xs: 0, md: 0, lg: 6 }}>
                                    <PopularityChart
                                      value={parseInt(chatGPTData?.popularityRating) * 10 || 10}
                                    />
                                  </Box>
                                )}
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                              <Box
                                sx={{
                                  border: "1px solid #000088",
                                  borderRadius: "39px",
                                  background: "#F3FBFF",
                                  height: "100%",
                                  boxSizing: "inherit",
                                }}
                                p={{ xs: 2, md: 3, lg: 2 }}
                              >
                                <Typography
                                  color="primary"
                                  sx={{
                                    fontFamily: "Montserrat, cursive",
                                    textAlign: "center",
                                    fontSize: { xs: "24px", lg: "28px" },
                                    fontWeight: 700,
                                    // mb: `${isMobile ? "0px" : "40px"}`,
                                  }}
                                >
                                  Enterprise Adoption
                                </Typography>
                                {chatGptButton ? (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      my: 5,
                                    }}
                                  >
                                    <CircularProgress />
                                  </Box>
                                ) : (
                                  <>
                                    <IndustrialUsageChart
                                      value={parseInt(chatGPTData?.percentageUsage || 10)}
                                    />
                                  </>
                                )}
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} lg={6}>
                              <Box
                                sx={{
                                  // maxWidth: "377px",
                                  // width: "100%",
                                  border: "1px solid #000088",
                                  // p: "30px 62px",
                                  borderRadius: "39px",
                                  background: "#F3FBFF",
                                  height: "100%",
                                  boxSizing: "inherit",
                                }}
                                p={{ xs: 2, md: 3, lg: 2 }}
                              >
                                <Typography
                                  color="primary"
                                  sx={{
                                    fontFamily: "Montserrat, cursive",
                                    textAlign: "center",
                                    // fontSize: {
                                    //   xs: "21px",
                                    //   sm: "24px",
                                    //   md: "24px",
                                    //   lg: "32px",
                                    //   xl: "32px",
                                    // },
                                    fontSize: { xs: "24px", lg: "28px" },
                                    fontWeight: 700,
                                    // mb: "10px",
                                  }}
                                >
                                  Learn & Earn Recommendations
                                </Typography>
                                {chatGptButton ? (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      my: 5,
                                    }}
                                  >
                                    <CircularProgress />
                                  </Box>
                                ) : (
                                  <Box
                                    pl={{ xs: 0, md: 2, xl: 3 }}
                                    mt={{ xs: 0, md: 3, lg: 3, xl: 3 }}
                                  >
                                    {chatGPTData?.latestTechnologies &&
                                    Array.isArray(chatGPTData?.latestTechnologies) ? (
                                      <>
                                        {" "}
                                        {chatGPTData?.latestTechnologies?.map((item, index) => (
                                          <Stack direction="column" key={index} p={1}>
                                            <List sx={{ padding: 0 }}>
                                              <ListItem sx={{ padding: 0 }}>
                                                <ListItemIcon sx={{ minWidth: "unset", mr: 1 }}>
                                                  <img
                                                    style={{ maxWidth: "36px" }}
                                                    src="../../assets/images/fire-icon.svg"
                                                    alt="fire"
                                                  />
                                                </ListItemIcon>
                                                <ListItemText>
                                                  {" "}
                                                  <Typography
                                                    color="primary"
                                                    sx={{
                                                      fontFamily: "Poppins, sans-serif",
                                                      fontSize: `${isMobile ? "16px" : "24px"}`,
                                                    }}
                                                  >
                                                    {item?.name}
                                                  </Typography>
                                                </ListItemText>
                                                <ListItemIcon sx={{ minWidth: "unset" }}>
                                                  {/* <Box pl={1}> */}
                                                  <StyledTooltip
                                                    PopperProps={{
                                                      disablePortal: true,
                                                    }}
                                                    sx={{
                                                      border: "1px solid #008",
                                                      backgroundColor: "#FFF",
                                                      borderRadius: "20px",
                                                      p: "11px 34px !important",
                                                      filter:
                                                        "drop-shadow(0px 0px 12px rgba(0, 0, 136, 0.55))",
                                                      maxWidth: { xs: 270, sm: 400 },
                                                      p: "11px 11px !important",
                                                      "& .MuiTooltip-arrow": {
                                                        color: "rgba(0,0,136,1)",
                                                      },
                                                    }}
                                                    onClose={() => handleTooltipClose(index)}
                                                    open={openTooltip[index] || false}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title={
                                                      <CustomTooltipTitle
                                                        data={item}
                                                        index={index}
                                                      />
                                                    }
                                                    arrow
                                                    placement={isMobile ? "top" : "right"}
                                                  >
                                                    <Button
                                                      sx={{
                                                        maxWidth: "129px",
                                                        width: "100%",
                                                        p: "2px 8px",
                                                        color: "#000088",
                                                        borderRadius: "23px",
                                                        border: "2px solid #000088",
                                                        textTransform: "unset",
                                                      }}
                                                      endIcon={
                                                        <KeyboardArrowRight
                                                          sx={{ fontSize: "24px" }}
                                                        />
                                                      }
                                                      onClick={() => handleTooltipOpen(index)}
                                                    >
                                                      Learn more
                                                    </Button>
                                                  </StyledTooltip>
                                                  {/* </Box> */}
                                                </ListItemIcon>
                                              </ListItem>
                                            </List>
                                          </Stack>
                                        ))}
                                      </>
                                    ) : (
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        flexDirection={"column"}
                                        textAlign={"center"}
                                      >
                                        <Box>
                                          <img
                                            src="../../assets/images/empty-list.svg"
                                            alt="data not found"
                                            style={{
                                              maxWidth: `${isMobile ? "50%" : "80%"}`,
                                            }}
                                          />
                                        </Box>
                                        <Typography
                                          sx={{
                                            fontFamily: "Montserrat, cursive",
                                            textAlign: "center",
                                            fontSize: { xs: "18px", lg: "21px", xl: "32px" },
                                          }}
                                        >
                                          Data not found
                                        </Typography>
                                        {initialCount <= 3 && (
                                          <Box
                                            display={"flex"}
                                            justifyContent={"center"}
                                            alignItems={"center"}
                                          >
                                            <Typography pr={1}>Retry with </Typography>
                                            <Button
                                              component="div"
                                              sx={{
                                                maxWidth: "129px",
                                                width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                padding: "7px 12px",
                                                borderRadius: "27px",
                                                background: `linear-gradient(148deg, #0BA2FF -2.88%, #C2187F 87.25%, #100C3D 135.99%)`,
                                                transition: "all 0.3s linear",
                                                "&:hover": {
                                                  background:
                                                    "linear-gradient(103deg, #661BB0  18.51%,#009EF7   67.8%,#100C3D  115.08%)",
                                                  transition: "all 0.3s linear",
                                                },
                                              }}
                                              onClick={() => RetryGPT(checkSkill)}
                                              size="small"
                                            >
                                              <RocketLaunch
                                                sx={{ color: "#FFF", fontSize: "23px" }}
                                              />
                                              <Typography
                                                sx={{
                                                  fontSize: "16px",
                                                  color: "#FFF",
                                                  fontWeight: 600,
                                                  textTransform: "capitalize",
                                                }}
                                              >
                                                AI Boost
                                              </Typography>
                                            </Button>
                                          </Box>
                                        )}
                                      </Box>
                                    )}
                                  </Box>
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                  {/* {checkSkill !== "" && poweredByChatGPT && !chatgptError && (
                    <Grid item xs={12} xl={3.1}>
                      <Box
                        sx={{
                          maxWidth: { xs: "300px", md: "400px", xl: "500px" },
                          width: "100%",
                          display: "block",
                          m: "0px auto",
                          backgroundImage: `url('/assets/images/result-interview-bg.png')`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          borderRadius: "25px",
                          p: "8px 30px 42px",
                          textAlign: "center",
                        }}
                      >
                        <Box
                          sx={{
                            maxWidth: { xs: "170px", xl: "252px" },
                            width: "100%",
                            height: "auto",
                            m: "0px auto",
                            "& >img": {
                              width: "100%",
                              height: "100%",
                            },
                          }}
                        >
                          <img src="/assets/images/question-marks.png" alt="question-marks" />
                        </Box>
                        <Typography
                          sx={{
                            fontSize: { xs: "23px", lg: "32px" },
                            color: "#FFF",
                            fontWeight: 600,
                            mb: "35px",
                          }}
                        >
                          Get interview questions for preparation
                        </Typography>
                        <Button
                          sx={{
                            background: "#FFC218",
                            borderRadius: "100px",
                            maxWidth: "205px",
                            width: "100%",
                            fontSize: "24px",
                            border: "2px solid transparent",
                            textTransform: "unset",
                            "&:hover,&:active": {
                              background: "#FFF",
                              border: "2px solid #000",
                            },
                          }}
                          disabled={chatGptButton}
                          onClick={handleGetInterviewQuestions}
                        >
                          Get it now
                        </Button>
                      </Box>
                    </Grid>
                  )} */}
                </Grid>
              </Grid>
            </>
          ) : (
            ""
          )}
          {getInterviewQuestions && (
            <Grid item xs={12}>
              <Box
                sx={{
                  border: "1px solid #000088",
                  borderRadius: "25px",
                  p: { xs: "20px 25px 0px", md: "20px 55px 0px" },
                }}
              >
                <TabContext value={value}>
                  <TabList
                    onChange={handleChange}
                    indicatorColor="info"
                    sx={{
                      "& .MuiTab-root": {
                        fontSize: { xs: "18px", sm: "24px" },
                        color: "#A0A0A0",
                        fontWeight: 600,
                        textTransform: "unset",
                      },
                      "& .MuiTabs-indicator": {
                        backgroundColor: "#0BA2FF !important",
                        height: "3px",
                      },
                      "& .Mui-selected": {
                        color: "#000",
                      },
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                  >
                    {Object.keys(staticQuestions).map((item, index) => (
                      <Tab value={item} label={item} disableRipple key={index} />
                    ))}
                  </TabList>
                  {Object.keys(staticQuestions).map((item, index) => (
                    <TabPanel key={index} value={item} sx={{ p: "50px 0px" }}>
                      {staticQuestions[`${item}`]?.map((skill, index) => (
                        <Box sx={{ mb: "40px" }} key={index}>
                          <Typography sx={{ fontSize: "20px", fontWeight: 500 }}>
                            Q.{index + 1} {skill?.question}
                          </Typography>
                          <Typography
                            sx={{ fontSize: "20px", color: "#6A6A6A", lineHeight: "27px" }}
                          >
                            Ans : {skill?.answer}
                          </Typography>
                        </Box>
                      ))}
                    </TabPanel>
                  ))}
                </TabContext>
              </Box>
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    </ThemeProvider>
  );
}

export default TechRelevanceV2;
