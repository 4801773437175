import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import { Grid, Box, Typography, Fade } from "@mui/material";
import animationData from "../animations/loading.json";

const Loading = () => {
  const [currentMessage, setCurrentMessage] = useState(0);
  const [delayMessage, setDelayMessage] = useState(false);

  const messages = [
    "Calculating your TechRelevance...",
    "Analyzing your skills and inputs...",
    "Matching your expertise with industry trends...",
    "Crunching the numbers for optimal results...",
    "Almost there, hang tight!",
  ];

  useEffect(() => {
    // Cycle through short messages every 4 seconds (0-20 seconds)
    const messageInterval = setInterval(() => {
      setCurrentMessage((prev) => (prev + 1) % messages.length);
    }, 4000); // Change message every 4 seconds

    // Trigger the delay message after 20 seconds
    const delayTimer = setTimeout(() => {
      setDelayMessage(true);
      clearInterval(messageInterval); // Stop cycling messages
    }, 20000); // 20 seconds

    return () => {
      clearInterval(messageInterval);
      clearTimeout(delayTimer);
    };
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          my: 5,
          textAlign: "center",
        }}
      >
        <Lottie options={defaultOptions} height={200} width={200} />
        <Fade in timeout={800} key={currentMessage}>
          <Typography
            variant="h6"
            sx={{ mt: 2, fontWeight: 500, color: "#555" }}
          >
            {delayMessage
              ? "We're still calculating your TechRelevance. This may take a bit longer based on your inputs."
              : messages[currentMessage]}
          </Typography>
        </Fade>
      </Box>
    </Grid>
  );
};

export default Loading;
