import React, { useState } from "react";
import { TextField, Button, Typography, Box, Grid, Container, Paper, Link } from "@mui/material";
// import Header from "../components/Header";
import toast from "react-hot-toast";
import axios from "axios";
import FooterComponent from "../Components/Footer";
import { useMediaQuery } from "@mui/material";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");


  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: name,
      email: email,
      message: message,
    };
    // Here, you can add your email sending logic using an API or library
    sendEmail(data);
    // Reset form fields
    setName("");
    setEmail("");
    setMessage("");
  };

  const sendEmail = async (data) => {
    // Implement your email sending logic here
    // console.log("Sending email:", data);
    try {
      const response = await axios.post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: data?.name,

            email: data?.email,
          },

          to: [{ email: "support@vucaware.com" }],

          subject: "Contact Us (True Selfy)",

          htmlContent: `<p>${data?.message}</p>`,
        },

        {
          headers: {
            "api-key": process.env.REACT_APP_SENDIN_BLUE_API_KEY,
            "Content-Type": "application/json",
          },
        }
      );

      // console.log(response.data);
      toast.success("message sent sucessfully");
    } catch (error) {
      console.error(error);
      toast.error("Network Error");
    }
  };

  return (
    <React.Fragment>
      {/* <Header /> */}
      <Grid item xs={7.2} sm={6.5} order={{ sm: 2, md: 1 }}>
        <Box
          sx={{
            p: { xs: 1, md: 2, xl: 2 },
            display: "flex",
            justifyContent: "center",
            maxWidth: { xs: "60px", md: "60px", lg: "80px" },
          }}
        >
          <a href="/">
            <img
              src={"../../assets/images/TrueSelfy-logo.svg"}
              alt="true-selfy"
              loading="lazy"
              style={{
                maxWidth: `${isMobile ? "80px" : "80px"}`,
                width: "100%",
              }}
            />
          </a>
        </Box>
      </Grid>
      <Grid p={5} sx={{ backgroundColor: "#D0D0D0", marginBottom: "20px" }}>
        <Box
          sx={{
            maxWidth: 400,
            margin: "0 auto",
            marginTop: "99px",
            backgroundColor: "#ffff",
            boxShadow: "16px 16px 8px rgba(0, 0, 0, .1)",
            borderRadius: "20px",
          }}
          display={"flex"}
          flexDirection={"row"}
          p={4}
        >
          <Box sx={{ maxWidth: 400 }}>
            <Typography variant="h4" gutterBottom textAlign={"center"} sx={{ fontFamily: `"Poppins", sans-serif`, fontSize: 25, fontWeight: "bold" }}>
              Contact Us
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                required
                margin="normal"
              />
              <TextField
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                required
                margin="normal"
              />
              <TextField
                label="Message"
                multiline
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                fullWidth
                required
                margin="normal"
              />
              <Box display="flex" justifyContent={"center"}>
                <Button textAlign="center" type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
        <Box
          sx={{
            maxWidth: 500,
            margin: "30px auto",
            backgroundColor: "#ffff",
            boxShadow: "16px 16px 8px rgba(0, 0, 0, .1)",
            borderRadius: "20px",
          }}
          display={"flex"}
          flexDirection={"row"}
          p={{ xs: 3, md: 4, lg: 4, xl: 4 }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4" sx={{ fontFamily: `"Poppins", sans-serif`, fontSize: 25,fontWeight: "bold" }}>
                You can reach us at:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ fontFamily: `"Poppins", sans-serif`, fontWeight: "bold" }} pb={0.5}>
                Vucaware Emerging Technologies Private Limited
              </Typography>
              <Typography variant="body1" sx={{ fontFamily: `"Poppins", sans-serif`}}>Building E2, Flat No. 2,</Typography>
              <Typography variant="body1" sx={{ fontFamily: `"Poppins", sans-serif`}}>ITI Road, Survey No. 138,</Typography>
              <Typography variant="body1" sx={{ fontFamily: `"Poppins", sans-serif`}}>Shirine Garden, Aundh,</Typography>
              <Typography variant="body1" sx={{ fontFamily: `"Poppins", sans-serif`}}>Pune, Maharashtra - 411007</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ fontFamily: `"Poppins", sans-serif`, fontWeight: "bold" }}>
                Email: <Link href="mailto:support@vucaware.com">support@vucaware.com</Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <FooterComponent />
    </React.Fragment>
  );
};

export default ContactUs;
