export function DataDecryption(
  jsonString,
  secretEncodeDecodeString,
  staticRandomEncodeDecodeString
) {
  const decodedString = atob(jsonString);
  const getOldStringOne = decodedString.replace(
    new RegExp(secretEncodeDecodeString, "g"),
    ""
  );
  const str = staticRandomEncodeDecodeString;
  const escapedString = str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const regex = new RegExp(escapedString, "g");
  const getOldStringTwo = getOldStringOne.replace(regex, '"');
  const json = JSON.parse(getOldStringTwo);
  return json;
}

export function DataEncryption(
  json,
  secretEncodeDecodeString,
  staticRandomEncodeDecodeString
) {
  const jsonString = JSON.stringify(json);
  const customStringJson =
    secretEncodeDecodeString + jsonString + secretEncodeDecodeString;
  const newCustomStringJson = customStringJson.replace(
    /"/g,
    staticRandomEncodeDecodeString
  );
  const encodedString = btoa(newCustomStringJson);
  return encodedString;
}

export function getExperiences(value) {
  if (value) {
    const totalMonths = parseInt(value, 10);
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;
    let experienceString = "";
    let emoji = "";

    // Define experience text and emoji based on input
    if (years > 0) {
      experienceString += `${years} ${years > 1 ? "years" : "year"}`;
      emoji = years >= 5 ? "🌟" : years >= 2 ? "🔥" : "👍"; // Customize emoji based on years
    }
    if (months > 0) {
      experienceString += ` ${months} ${months > 1 ? "months" : "month"}`;
      if (years === 0) {
        emoji = months >= 6 ? "✨" : "😊"; // Use different emoji for months
      }
    }

    return experienceString.trim();
  } else {
    return "1";
  }
}
